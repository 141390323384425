<script setup>
import { ref, onBeforeMount } from 'vue'
import useClients from "@/hooks/useClients";

const week = ref()
const items = ref([])
const clientId = ref()
const fridays = ref([])

const { clients, fetchClients } = useClients();

onBeforeMount(async () => {
    await fetchClients();
});

const fileUpload = () => {
}

const addItem = () => {
    items.value.push({})
}

const submit = () => {
}


</script>

<template>
    <div>
        <div class="ml-6 mb-4 text-h5">Add SLO Orders</div>
        <v-divider></v-divider>
    </div>

    <div class="pa-4">
        <v-card class="pa-2">
            <div class="p-4 mt-8">
                <v-row class="mb-3 ml-6">
                    <v-col cols="3">
                        <v-autocomplete density="compact" variant="outlined" :items="clients" v-model="clientId"
                            hide-details item-title="name" item-value="id" placeholder="Select Customer..." />
                    </v-col>
                    <v-col cols="3">
                        <v-autocomplete density="compact" variant="outlined" :items="fridays" v-model="week"
                            hide-details placeholder="Select Week..." />
                    </v-col>
                    <v-col cols="3" class="mb-4">
                        <v-btn @click="submit" dark color="green" block :disabled="!week || !clientId">Create
                            Orders</v-btn>
                    </v-col>
                </v-row>

                <v-row class="mb-2 ml-6" v-if="items.length > 0">
                    <v-col cols="1">
                        <strong>Origin Name</strong>
                    </v-col>
                    <v-col cols="1">
                        <strong>Customer Number</strong>
                    </v-col>
                    <v-col cols="1">
                        <strong>Customer Name</strong>
                    </v-col>
                    <v-col cols="1">
                        <strong>Store Number</strong>
                    </v-col>
                    <v-col cols="1">
                        <strong>Order Number</strong>
                    </v-col>
                    <v-col cols="1">
                        <strong>PO Number</strong>
                    </v-col>
                    <v-col cols="1">
                        <strong>SOS Number</strong>
                    </v-col>
                    <v-col cols="1">
                        <strong>SKU</strong>
                    </v-col>
                    <v-col cols="1">
                        <strong>Description</strong>
                    </v-col>
                    <v-col cols="1">
                        <strong>Case Qty</strong>
                    </v-col>
                    <v-col cols="1">
                        <strong>Bottle Qty</strong>
                    </v-col>
                </v-row>

                <v-row v-for="(item, index) in items" :key="index" class="mb-4 ml-6">
                    <v-col cols="1">
                        <v-text-field hide-details density="compact" variant="outlined" v-model="item.origin_name" />
                    </v-col>
                    <v-col cols="1">
                        <v-text-field hide-details density="compact" variant="outlined" v-model="item.origin_name" />
                    </v-col>
                    <v-col cols="1">
                        <v-text-field hide-details density="compact" variant="outlined" v-model="item.origin_name" />
                    </v-col>
                    <v-col cols="1">
                        <v-text-field hide-details density="compact" variant="outlined" v-model="item.origin_name" />
                    </v-col>
                    <v-col cols="1">
                        <v-text-field hide-details density="compact" variant="outlined" v-model="item.origin_name" />
                    </v-col>
                    <v-col cols="1">
                        <v-text-field hide-details density="compact" variant="outlined" v-model="item.origin_name" />
                    </v-col>
                    <v-col cols="1">
                        <v-text-field hide-details density="compact" variant="outlined" v-model="item.origin_name" />
                    </v-col>
                    <v-col cols="1">
                        <v-text-field hide-details density="compact" variant="outlined" v-model="item.origin_name" />
                    </v-col>
                    <v-col cols="1">
                        <v-text-field hide-details density="compact" variant="outlined" v-model="item.origin_name" />
                    </v-col>
                    <v-col cols="1">
                        <v-text-field hide-details density="compact" variant="outlined" v-model="item.origin_name" />
                    </v-col>
                    <v-col cols="1">
                        <v-text-field hide-details density="compact" variant="outlined" v-model="item.origin_name" />
                    </v-col>

                </v-row>
                <div class="text-center mb-4">
                    <v-btn @click="addItem">+Add Item</v-btn>
                </div>
            </div>

            <div>
                <v-col cols="3">
                    <v-file-input label="File input" density="compact" hide-details="auto" class="mb-4"></v-file-input>
                    <v-btn color="primary" size="small" class="ml-10">Upload File</v-btn>
                </v-col>
            </div>
        </v-card>
    </div>
</template>